import React, { Component } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
// import { BrowserRouter as Router, Route, Link} from 'react-router-dom'
// import Select from '@material-ui/core/Select';
// import InputLabel from '@material-ui/core/InputLabel';
// import FormControl from '@material-ui/core/FormControl';
import Snackbar from '@material-ui/core/Snackbar';
import Gvar from './Gvar';
import { sha256 } from 'js-sha256';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Utils, {loadProfile} from './Utils';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ContainerLayout from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Logger from './Logger';
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    }));
    
export function TopBar(props) {
    var context = props.context
    const classes = useStyles();
    return (
        <div className={classes.root}>
        <AppBar position="fixed">
            <Toolbar variant="dense">
            <IconButton edge="start" onClick={()=>{
                context.props.history.goBack()
            }} className={classes.menuButton} color="inherit" aria-label="menu">
                <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
                {context.state.title}
            </Typography>
            {/* <Button color="inherit">Login</Button> */}
            </Toolbar>
            <Snackbar
                style={{marginTop:56}}
                anchorOrigin={{ vertical: 'top', horizontal :'right' }}
                open={context.state.showSnackBar}
                onClose={context.handleCloseSnackBar}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{context.state.textSnackBar}</span>}
            />
        </AppBar>
        </div>
    );
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ProdukPrefix extends Component {
    state = {
        jenisKartu : '',
        showSnackBar : false,
        textSnackBar : '',
        listProduk : [],
        showConfirmDialog : false,
        showConfirmPIN : false,
        title:'',
        showLoading : false
    }
    constructor(props){
        super(props);
        this.jenisKartu = "";
        this.jenis = "";
        this.titlePage = "";
        var currentURL = window.location.href;
        if(currentURL.includes('paketdata')){
            this.jenis = "PAKET_DATA";
            this.titlePage = "Paket Data";
        }
        if(currentURL.includes('pulsa')){
            this.jenis = "PULSA";
            this.titlePage = "Pulsa";
        }
        if(currentURL.includes('telepon')){
            this.jenis = "PAKET_TELEPON";
            this.titlePage = "Paket Telepon";
        }
        this.dataConfirm = {
            nama_produk : ''
        }
         
        this.refPIN = React.createRef();
    }

    componentDidMount(){
        this.setState({title :this.titlePage});
        setTimeout(() => {
            var noTujuan = document.getElementById("inputNoTujuan");
            noTujuan.value = "";
            noTujuan.focus();
        }, 500);
        loadProfile();
    }

    async handleClickOpenConfirm(rowData){
        Logger.d("rowData",rowData);
        await loadProfile();
        this.dataConfirm = rowData;
        
        this.sisaSaldo = parseFloat(Gvar.saldo)-parseFloat(rowData.h_jual)
        Logger.d('Gvar.saldo = '+Gvar.saldo+'; rowData.h_jual='+rowData.h_jual)
        Logger.d('this.sisaSaldo',this.sisaSaldo);
        this.setState({showConfirmDialog:true});
    };
    
    handleCloseConfirm = () => {
        this.setState({showConfirmDialog:false});
    };

    deteksiOperator(){
        this.nohp = document.getElementById("inputNoTujuan").value;
        // console.log(nohp.value);
        if(this.nohp.length >= 4){
            this.getDataProduk(this.nohp.substr(0,4));
        }else{
            this.setState({listProduk:[]})
        }
    }

    handleChange = event => {
        // this.setState({ [event.target.name]: event.target.value });
    };

    handleClose = () => {
        this.setState({ showSnackBar: false });
    };

    getDataProduk(prefix){
        if(prefix===""){
            return;
        }
        this.setState({showLoading:true})

        var data_member = JSON.parse(localStorage.getItem('data_member'));
        
        var bodyJson = {jenis:this.jenis,prefix : prefix,id_member:data_member.id,token:Utils.getMyToken()}
        // console.log(bodyJson);
        fetch(Gvar.server+'index.php/produk/prefix', {
        method: 'POST',
        body: JSON.stringify(bodyJson)
        }).then(res=>res.json()
            ).then(res => {
                //console.log(res)
                this.setState({listProduk:res.data})
                this.setState({showLoading:false});
            }
            ).catch(err => {
                this.setState({showLoading:false});
                this.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal"});
             })
    }

    async sendTransaction (){
        var data_member = JSON.parse(localStorage.getItem('data_member'));
        var noTujuan = document.getElementById('inputNoTujuan').value;
        var kodeProduk = this.dataConfirm.kode_produk;
        var pinTransaksi = document.getElementById('inputPINTransaksi').value;
        if(noTujuan === ""){
            this.setState({ showSnackBar: true, textSnackBar : "no tujuan belum diisi"}); return;
        }
        if(kodeProduk === ""){
            this.setState({ showSnackBar: true, textSnackBar : "nominal tujuan belum diisi"}); return;
        }
        if(pinTransaksi === ""){
            this.setState({ showSnackBar: true, textSnackBar : "pin transaksi belum diisi"}); return;
        }
        this.setState({showLoading:true});
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

        var bodyJson = { 
            id_member: data_member.id,
            no_tujuan: noTujuan,
            kode_produk:kodeProduk,
            pin_transaksi:Utils.getHash(pinTransaksi),
            trx_source : 'WEB_APP',
            token : Utils.getMyToken(),
            metode_pembayaran : 'EWALLET',
            channel_code : 'SALDO',
            inv_info_pembayaran : { 
                nama_pengirim : Gvar.appName,
                alamat_pengirim : "Bekasi",
                email_pengirim : Gvar.senderMail,
                link_belanja_pengirim: "",
                no_wa_pengirim : Gvar.whatsappNumber,
                hotline_pengirim : "",
                web_pengirim : Gvar.webSender,
                nama_penerima : data_member.nama,
                alamat_penerima : data_member.alamat,
                email_penerima : data_member.email,
                no_hp_penerima : noTujuan,
                nama_ewallet : "Saldo"
            }
        }
        // console.log(bodyJson);
        await fetch(Gvar.server+'index.php/transaksi', {
            method: 'post',
            body: JSON.stringify(bodyJson)
        }).then(res=>res.json()).then(res => {
                //console.log(res)
                // this.setState({listProduk:res})
                if(res.data.status == "SUKSES"){
                    loadProfile();
                    this.setState({ showLoading:false,showSnackBar: true, textSnackBar : res.data.status+", "+res.data.pesan});
                    document.getElementById("inputNoTujuan").value = "";
                }else if(res.data.status == "PENDING"){
                    this.setState({showSnackBar: true, textSnackBar : res.data.status+", "+res.data.pesan});
                    Gvar.checkStatusService = setInterval(() => {
                        this.checkStatus(res.data.id_request);
                    }, 2000);
                }else{
                    loadProfile();
                    this.setState({ showLoading:false,showSnackBar: true, textSnackBar : res.data.status+", "+res.data.pesan});
                }
            }
            ).catch(err => {
                this.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal"});
                this.setState({showLoading:false})
             })
    }

    checkStatus(idRequest){
        this.setState({showLoading:true})
        var data_member = JSON.parse(localStorage.getItem('data_member'));
        var bodyJson = { 
            id_member: data_member.id,
            token : Utils.getMyToken()
        }
        // console.log(bodyJson);
        fetch(Gvar.server+'index.php/transaksi/cek/'+idRequest, {
            method: 'post',
            body: JSON.stringify(bodyJson)
        }).then(res=>res.json()).then(res => { 
                if(Gvar.homeContext!=null){
                    Gvar.homeContext.pullMessage();
                }    
                //console.log(res)
                // this.setState({listProduk:res})
                if(res.data.status === "SUKSES" || res.data.status === "GAGAL"){
                    loadProfile();
                    clearInterval(Gvar.checkStatusService);
                    this.setState({showLoading:false,showSnackBar: true, textSnackBar : res.data.status+", "+res.data.pesan});
                    if(res.data.status === "SUKSES"){
                        document.getElementById("inputNoTujuan").value = "";
                    }
                }
            }
        ).catch(err => {
            clearInterval(Gvar.checkStatusService);
            this.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal"});
            this.setState({showLoading:false})
        })
    }

    handleCloseSnackBar = () =>{
        this.setState({ showSnackBar: false, textSnackBar :""});
    }

    render() {
        // const classes = useStyles();
        const polygon = (
            <>
                <Dialog
                    open={this.state.showConfirmDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{"Konfirmasi"}</DialogTitle>
                    <DialogContent>
                        <table>
                            <tbody>
                                <tr>
                                    <td>No Tujuan</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right"}}>{this.nohp}</td>
                                </tr>
                                <tr>
                                    <td>Produk</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right"}}>{this.dataConfirm.nama_produk}</td>
                                </tr>
                                <tr>
                                    <td>Keterangan Produk</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right"}}>{this.dataConfirm.keterangan_produk}</td>
                                </tr>
                                <tr>
                                    <td>Harga</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right"}}>{Utils.formatRupiah(this.dataConfirm.h_jual,'.')}</td>
                                </tr>
                                <tr>
                                    <td>Saldo</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right"}}>{Utils.formatRupiah(Gvar.saldo,'.')}</td>
                                </tr>
                                <tr>
                                    <td>Sisa Saldo</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right"}}>{Utils.formatRupiah(this.sisaSaldo,'.')}</td>
                                </tr>
                            </tbody>
                        </table>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.handleCloseConfirm} color="primary">
                        Batal
                    </Button>
                    <Button onClick={()=>{
                        this.setState({showConfirmPIN:true,showConfirmDialog:false})
                        setTimeout(() => {
                            document.getElementById('inputPINTransaksi').focus();
                        }, 500);
                    }} color="primary">
                        Lanjut
                    </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.showConfirmPIN}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleCloseConfirm}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{"PIN Transaksi"}</DialogTitle>
                    <DialogContent>
                        <TextField
                                id="inputPINTransaksi"
                                label="PIN Transaksi"
                                type="password"
                                name="password"
                                margin="normal"
                                autoComplete="none"
                                variant="outlined"
                                autoFocus
                                innerRef={this.refPIN}
                            />
                    </DialogContent>
                    <DialogActions>
                    <Button disabled={this.state.showLoading} onClick={()=>{
                        this.setState({showConfirmPIN:false});
                    }} color="primary">
                        Batal
                    </Button>
                    <Button onClick={()=>{
                        this.setState({showConfirmPIN:false});
                        this.sendTransaction();
                        document.getElementById('inputPINTransaksi').value ="";
                    }} color="primary">
                        Beli
                    </Button>
                    </DialogActions>
                </Dialog>
                <Card style={{marginTop : 20}}>
                    <CardContent>
                    <Grid
                        item xs={12}
                        container
                        direction="column"
                        >
                            <TextField
                                id="inputNoTujuan"
                                label="Nomor Tujuan"
                                type="number"
                                name="nomorhp"
                                autoComplete="none"
                                margin="normal"
                                variant="outlined"
                                onKeyUp={()=>this.deteksiOperator()}
                            />
                        </Grid>
                    </CardContent>
                </Card> 
                {this.state.showLoading?<LinearProgress style={{marginTop:10}} color="secondary"  variant="query" />:null}
                {this.state.listProduk.map((data,index) => (
                    <Card onClick={()=>{
                        this.setState({ showSnackBar: false})
                        this.handleClickOpenConfirm(data)
                    }} key={data.kodeProduk} style={{marginTop : 20}}>
                        <CardContent style={{position:'relative'}}>
                            <span style={{fontSize:18}}>
                                {data.nama_produk}    
                            </span> <br></br>
                            <span>
                            {data.keterangan_produk}
                            </span>
                            <span style={{position:'absolute',top:16,right:30}}>
                                {Utils.formatRupiah(data.h_jual,'.')} 
                            </span>
                            <span style={{position:'absolute',top:16,right:0}}>
                            <ArrowRightIcon></ArrowRightIcon> 
                            </span>
                        </CardContent>
                    </Card>    
                // <option key={data.id} value={data.kode_produk}>{data.nama_produk} ({data.h_jual})</option>
                ))}
            </>
          );
                    
        return (
            <>
                <TopBar context={this}></TopBar>
                <ContainerLayout style={{marginTop:75}} maxWidth="sm">
                    <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
                    {polygon}
                    </Grow>
                </ContainerLayout>
            </>
        );
    }
}


export default ProdukPrefix;
