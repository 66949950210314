import React, { Component } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import Snackbar from '@material-ui/core/Snackbar';
import Gvar from './Gvar';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Utils, {loadProfile} from './Utils';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ContainerLayout from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import Logger from './Logger';
import { Divider, FormControl, InputLabel, List, ListItem, ListItemSecondaryAction, ListItemText, ListSubheader, MenuItem, Select } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    }));
    
export function TopBar(props) {
    var context = props.context
    const classes = useStyles();
    var currentURL = window.location.href;
    // loadProfile();
    var jenis = currentURL.split("jenis=")[1];
    return (
        <div className={classes.root}>
        <AppBar position="fixed">
            <Toolbar variant="dense">
            <IconButton edge="start" onClick={()=>{
                context.props.history.goBack()
            }} className={classes.menuButton} color="inherit" aria-label="menu">
                <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
                {context.state.title}
            </Typography>
            {/* <Button color="inherit">Login</Button> */}
            </Toolbar>
            <Snackbar
                style={{marginTop:56}}
                anchorOrigin={{ vertical: 'top', horizontal :'right' }}
                open={context.state.showSnackBar}
                onClose={context.handleCloseSnackBar}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{context.state.textSnackBar}</span>}
            />
        </AppBar>
        </div>
    );
}


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ProdukNonPrefix extends Component {
    state = {
        jenisKartu : '',
        showSnackBar : false,
        textSnackBar : '',
        listProduk : [],
        showConfirmDialog : false,
        showConfirmPIN : false,
        title : '',
        noTujuan : '',
        pinTransaksi : '',
        inputData : []
    }
    constructor(props){
        super(props);
        this.jenisKartu = "";
        this.jenis = "";
        this.titlePage = "";
        var currentURL = window.location.href;
        // loadProfile();
        this.data_member = JSON.parse(localStorage.getItem('data_member'));
        this.jenis = currentURL.split("jenis=")[1];
        this.group = decodeURI(currentURL.split("group=")[1].split("&jenis=")[0]);
        this.titlePage = this.group;
        this.dataConfirm = {
            nama_produk : ''
        }
    }

    componentDidMount(){
        loadProfile();
        this.setState({
            title:this.titlePage
        });

        // setTimeout(() => {
        //     this.refNoTujuan.focus();
        // }, 500);

        this.getDataProduk();

    }

    handleCloseSnackBar = () =>{
        this.setState({ showSnackBar: false, textSnackBar :""});
    }

    handleClickOpenConfirm(rowData){
        let msg = "";
        for(let item of this.state.inputData){
            if(item.is_required){
                if(item.value === ""){
                    msg = item.label + " harus diisi";
                    break;
                }
            }
        }

        if(msg !== ""){
            this.setState({ showSnackBar: true, textSnackBar : msg});
            return;
        }
        
        loadProfile();
        this.dataConfirm = rowData;
        this.sisaSaldo = parseFloat(Gvar.saldo)-parseFloat(rowData.h_jual)
        this.setState({showConfirmDialog:true});
    };
    
    handleCloseConfirm = () => {
        this.setState({showConfirmDialog:false});
    };

    handleChange = event => {
        // this.setState({ [event.target.name]: event.target.value });
    };

    handleClose = () => {
        this.setState({ showSnackBar: false });
    };

    getDataProduk(){
        this.setState({showLoading:true})
        var bodyJson = {jenis:this.jenis,group : this.group,id_member:this.data_member.id,token:Utils.getMyToken()}
        // console.log(bodyJson);
        fetch(Gvar.server+'index.php/produk/nonprefix', {
        method: 'post',
        body: JSON.stringify(bodyJson)
        }).then(res=>res.json()
            ).then(res => {
                //console.log(res)
                this.setState({listProduk:res.data})
                this.setState({showLoading:false});

                if(res.data.length > 0){
                    console.log(res.data[0].input_data)
                    if(res.data[0].input_data === null){
                        this.setState({inputData: [{type:'text', name:'no_tujuan', label:'No Tujuan', placeholder:''}]});
                    }else{
                        this.setState({inputData: JSON.parse(res.data[0].input_data)});
                    }
                }
            }
            ).catch(err => {
                this.setState({showLoading:false});
                this.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal"});
             })
    }

    sendTransaction (){
        var noTujuan = this.state.noTujuan;
        var kodeProduk = this.dataConfirm.kode_produk;
        let pinTransaksi = this.state.pinTransaksi;
        if(noTujuan === ""){
            this.setState({ showSnackBar: true, textSnackBar : "no tujuan belum diisi"}); return;
        }
        if(kodeProduk === ""){
            this.setState({ showSnackBar: true, textSnackBar : "nominal tujuan belum diisi"}); return;
        }
        if(pinTransaksi === ""){
            this.setState({ showSnackBar: true, textSnackBar : "pin transaksi belum diisi"}); return;
        }
        this.setState({showLoading:true})
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

        var bodyJson = { 
            id_request: null, 
            id_member: this.data_member.id, 
            token: Utils.getMyToken(), 
            no_tujuan: noTujuan,
            kode_produk: kodeProduk,
            kode_bank: "",
            nominal: null,
            keterangan: "",
            pin_transaksi: Utils.getHash(pinTransaksi),
            trx_source : "WEB_APP",
            pg_code: null,
            metode_pembayaran : "EWALLET",
            channel_code:"SALDO",
            draf: 1,
            inv_info_pembayaran : {
                nama_pengirim : Gvar.appName,
                alamat_pengirim : "Bekasi",
                email_pengirim : "support@fifapay.co.id",
                link_belanja_pengirim: "",
                no_wa_pengirim : "+628118996610",
                hotline_pengirim : "",
                web_pengirim : "http://fifapay.co.id",
                nama_penerima : "",
                alamat_penerima : "",
                email_penerima : "",
                no_hp_penerima : "",
                nama_ewallet : "Saldo"
            }
        }

        fetch(Gvar.server+'index.php/transaksi', {
            method: 'post',
            body: JSON.stringify(bodyJson)
        }).then(res=>res.json()
            ).then(res => {
                //console.log(res)
                // this.setState({listProduk:res})
                
                if(res.data.status === "SUKSES"){
                    loadProfile();
                    this.setState({showLoading:false, showSnackBar:true, textSnackBar:res.message});
                    // clear input
                    const newItems = [...this.state.inputData];
                    for (let index = 0; index < newItems.length; index++) {
                        newItems[index].value = "";
                        this.setState({ inputData:newItems, noTujuan: '' });
                    }
                }else if(res.data.status === "DRAF"){
                    loadProfile();
                    this.setState({showLoading:false, showSnackBar:true, textSnackBar:res.message});
                    // clear input
                    const newItems = [...this.state.inputData];
                    for (let index = 0; index < newItems.length; index++) {
                        newItems[index].value = "";
                        this.setState({ inputData:newItems, noTujuan: '' });
                    }
                }else if(res.data.status === "PENDING"){
                    this.setState({showLoading:false, showSnackBar:true, textSnackBar:res.message});

                    // clear input
                    const newItems = [...this.state.inputData];
                    for (let index = 0; index < newItems.length; index++) {
                        newItems[index].value = "";
                        this.setState({ inputData:newItems, noTujuan: '' });
                    }
                    
                    // Gvar.checkStatusService = setInterval(() => {
                    //     this.checkStatus(res.data.id_request);
                    // }, 2000);
                }else{
                    loadProfile();
                    this.setState({showLoading:false, showSnackBar: true, textSnackBar : res.data.pesan});
                }
            }).catch(err => {
                Logger.e(err.message);
                this.setState({showSnackBar: true, textSnackBar:"Koneksi Gagal"});
                this.setState({showLoading:false})
             })
    }

    checkStatus(idRequest){
        this.setState({showLoading:true})
        var bodyJson = { 
            id_member: this.data_member.id,
            token : Utils.getMyToken()
        }
        // console.log(bodyJson);
        fetch(Gvar.server+'index.php/transaksi/cek/'+idRequest, {
            method: 'post',
            body: JSON.stringify(bodyJson)
        }).then(res=>res.json()).then(res => { 
            if(Gvar.homeContext!=null){
                Gvar.homeContext.pullMessage(); 
            }   
                //console.log(res)
                // this.setState({listProduk:res})
                try {
                    if(res.data.status === "SUKSES" || res.data.status === "GAGAL"){
                        loadProfile();
                        clearInterval(Gvar.checkStatusService);
                        this.setState({showLoading:false,showSnackBar: true, textSnackBar : res.data.status+", "+res.data.pesan});
                        if(res.data.status === "SUKSES"){
                            this.setState({noTujuan:''})
                        }
                    }
                } catch (error) {
                    Logger.e(error.message)
                }
            }
        ).catch(err => {
            Logger.d(err.message);
            // clearInterval(Gvar.checkStatusService);
            this.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal"});
            this.setState({showLoading:false})
        })
    }

    render() {
        const polygon = (
            <>
                <Dialog
                    open={this.state.showConfirmDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{"Konfirmasi"}</DialogTitle>
                    <DialogContent>
                    <table>
                            <tbody>
                                <tr>
                                    <td>No Tujuan</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right"}}>{this.state.noTujuan}</td>
                                </tr>
                                <tr>
                                    <td>Produk</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right"}}>{this.dataConfirm.nama_produk}</td>
                                </tr>
                                <tr>
                                    <td>Keterangan Produk</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right"}}>{this.dataConfirm.keterangan_produk}</td>
                                </tr>
                                <tr>
                                    <td>Harga</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right"}}>{Utils.formatRupiah(this.dataConfirm.h_jual,'.')}</td>
                                </tr>
                                <tr>
                                    <td>Saldo</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right"}}>{Utils.formatRupiah(Gvar.saldo,'.')}</td>
                                </tr>
                                <tr>
                                    <td>Sisa Saldo</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right"}}>{Utils.formatRupiah(this.sisaSaldo,'.')}</td>
                                </tr>
                            </tbody>
                        </table>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.handleCloseConfirm} color="primary">
                        Batal
                    </Button>
                    <Button onClick={()=>{
                        this.setState({showConfirmPIN:true,showConfirmDialog:false})
                        setTimeout(() => {
                            this.refPIN.focus();
                        }, 500);
                    }} color="primary">
                        Lanjut
                    </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.showConfirmPIN}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleCloseConfirm}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{"PIN Transaksi"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <TextField
                            id="inputPINTransaksi"
                            label="PIN Transaksi"
                            type="password"
                            autoComplete="none"
                            name="password"
                            margin="normal"
                            variant="outlined"
                            inputRef={ref=>this.refPIN = ref}
                            value={this.state.pinTransaksi}
                            onChange={(e)=>this.setState({pinTransaksi:e.target.value})}
                            />
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={()=>{
                        this.setState({showConfirmPIN:false});
                    }} color="primary">
                        Batal
                    </Button>
                    <Button onClick={()=>{
                        this.setState({showConfirmPIN:false});
                        this.sendTransaction();
                        document.getElementById('inputPINTransaksi').value ="";
                    }} color="primary">
                        Beli
                    </Button>
                    </DialogActions>
                </Dialog>
                <Card style={{marginTop : 20}}>
                    <CardContent>
                    <Grid
                        item xs={12}
                        container
                        direction="column"
                        >
                            {this.state.inputData.map((data, index)=>{
                                if(data.type === 'text' || data.type === 'number'){
                                    return (
                                        <TextField
                                            id={"id_"+data.name}
                                            label={data.label}
                                            type={data.type}
                                            name={data.name}
                                            autoComplete="none"
                                            placeholder={data.placeholder}
                                            margin="normal"
                                            variant="outlined"
                                            value={data.value}
                                            onChange={(e)=>{
                                                const newItems = [...this.state.inputData];
                                                newItems[index].value = e.target.value;

                                                let noTujuan = '';
                                                let dest = newItems.map((data)=>data.value);
                                                noTujuan = dest.join(".");
                                                this.setState({ inputData:newItems, noTujuan: noTujuan });
                                            }}
                                        />
                                    )
                                }else if(data.type==='select'){
                                    return (
                                        <FormControl variant="outlined">
                                            <InputLabel id="input-select">{data.label}</InputLabel>
                                            <Select
                                            labelId="input-select"
                                            id="selectt"
                                            value={data.value}
                                            onChange={(e)=>{
                                                const newItems = [...this.state.inputData];
                                                newItems[index].value = e.target.value;

                                                let noTujuan = '';
                                                let dest = newItems.map((data)=>data.value);
                                                noTujuan = dest.join(".");
                                                this.setState({ inputData:newItems, noTujuan:noTujuan });
                                            }}
                                            label={data.label}
                                            >
                                                {data.options.map((option)=>{
                                                    return <MenuItem value={option}>{option}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    )
                                }else{
                                    return (<>Input type invalid</>)
                                }
                            })}
                            
                            {/* <FormControl>
                                <InputLabel htmlFor="kodeProduk">Nominal</InputLabel>
                                <Select
                                    native
                                    value={this.state.kodeProduk}
                                    onChange={this.handleChange('kodeProduk')}
                                    inputProps={{
                                    name: 'kodeProduk',
                                    id: 'kodeProduk',
                                    }}
                                >
                                    <option value="" />
                                    {this.state.listProduk.map(data => (
                                    <option key={data.id} value={data.kode_produk}>{data.nama_produk} ({data.h_jual})</option>
                                    ))}
                                </Select>
                            </FormControl> */}

                            {/* <TextField
                                id="inputPINTransaksi"
                                label="PIN Transaksi"
                                type="password"
                                name="password"
                                margin="normal"
                                variant="outlined"
                            /> */}

                            {/* <Button variant="contained" type="submit" onClick={()=>{
                                this.sendTransaction()
                            }}  size="large" color="primary" style={{margin : 10}}>
                                Kirim
                            </Button> */}
                        </Grid>
                    </CardContent>
                </Card> 
                {this.state.showLoading?<LinearProgress style={{marginTop:10}} color="secondary"  variant="query" />:null}
                <List
                    aria-labelledby="header-list"
                    subheader={
                    <ListSubheader id="header-list">
                        Pilih Produk
                    </ListSubheader>
                    }>
                    {this.state.listProduk.map(data => (
                        <>
                        <ListItem component={'li'} button onClick={()=>{
                            this.setState({ showSnackBar: false})
                            this.handleClickOpenConfirm(data)
                        }} >
                            <ListItemText primary={data.nama_produk} secondary={<>{Utils.formatRupiah(data.h_jual,'.')} <br/> {data.keterangan_produk}</>} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="show">
                                    <ArrowRightIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider variant="fullWidth" component={'li'}/>
                        </>
                    ))}
                </List>
            </>
          );
                    
        return (
            <>
                <TopBar context={this}></TopBar>
                <ContainerLayout style={{paddingTop:56}} maxWidth='sm'>
                    <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
                    {polygon}
                    </Grow>
                </ContainerLayout>
            </>
        );
    }
}


export default ProdukNonPrefix;
