import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Grow from '@material-ui/core/Grow';
import Gvar from './Gvar';
import Utils from './Utils';
import Grid from '@material-ui/core/Grid';
import Logger from './Logger';
import Button from '@material-ui/core/Button';
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = theme => ({
  root: {
    maxWidth: 600,
    flexGrow: 1,
    margin : 10
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing.unit * 4,
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 160,
    display: 'block',
    maxWidth: 600,
    overflow: 'hidden',
    width: '100%',
  },
});

class Dashboard extends Component {
  constructor(props){
    super(props);
    this.state = ({
      list_kategori_utama :[],
      activeStep : 0
    });
  }

  handleNext = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  handleStepChange = activeStep => {
    this.setState({ activeStep });
  };

  componentDidMount(){
    var isLoggedIn = localStorage.getItem('is_logged_in');
    if(isLoggedIn == null || isLoggedIn == undefined){
        Gvar.appContext.setState({isLoggedIn:false});
    }
    this.getIklan();
    this.getkategoriUtama();
    Gvar.NavigationTopContext.setState({title:'Beranda'});
  }

  getIklan(){
    var bodyJson = {id_member:Utils.getDataMember().id,token:Utils.getMyToken()};
    fetch(Gvar.server+'index.php/iklan', {
      method: 'post',
      body: JSON.stringify(bodyJson),
      }).then(res=>res.json()
      ).then(res => {
          Gvar.tutorialSteps = res.data;
          // this.setState({listProduk:res})
      }).catch(err => {
          // this.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal"});
          this.setState({showLoading:false})
      })
  }

  getkategoriUtama(){
    var bodyJson = {id_member:Utils.getDataMember().id,token:Utils.getMyToken()};
    fetch(Gvar.server+'index.php/produk/kategori-utama', {
      method: 'post',
      body: JSON.stringify(bodyJson),
      }).then(res=>res.json()
      ).then(res => {
          // console.log(res)
          var baseCategory = res.data.concat({jenis:'DAFTAR_PRODUK',icon:'view_all.png'});
          Logger.d(baseCategory);
          this.setState({list_kategori_utama:baseCategory}); 
          // this.setState({listProduk:res})
      }).catch(err => {
          // this.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal"});
          this.setState({showLoading:false})
      })
  }

  onClickKategoriUtama(jenis){
    // console.log(jenis);
    if(jenis.toUpperCase() === 'PULSA'){
      Gvar.homeContext.props.history.push('/pulsa');
    }else if(jenis.toUpperCase() === 'PAKET_DATA'){
      Gvar.homeContext.props.history.push('/paketdata');
    }else if(jenis.toUpperCase() === 'PAKET_TELEPON'){
      Gvar.homeContext.props.history.push('/pakettelepon');
    }else if(jenis.toUpperCase() === 'TOKEN_LISTRIK'){
      Gvar.homeContext.props.history.push('/tokenlistrik')
    }else if(jenis.toUpperCase() === 'DAFTAR_PRODUK'){
      Gvar.homeContext.props.history.push('/allproduct');
    }else{
      Gvar.homeContext.props.history.push('/group?jenis='+jenis);
    }
  }

  render() {
    const { classes, theme } = this.props;
    const { activeStep } = this.state;

    return (
      <div >
        <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
        <div>
          {!process.env.NODE_ENV || process.env.NODE_ENV === 'development'?(
            <div style={{padding:10,backgroundColor:'#e7c82c', color:'rgb(109 96 32)'}}>
              DEVELOPMENT MODE
            </div>
          ):null}
          <div style={{marginTop:4,marginBottom:4,color:Gvar.fontColor,fontSize:13}}>Halo, {Gvar.namaMember}</div>
          <hr style={{borderColor:'#f5f3f3'}}></hr>
          <Paper square="1" style={{paddingLeft:10,color:Gvar.fontColor,overflow:'auto',justifyContent:'center',fontSize:14}}>
          <div style={{minHeight:36,float:'left',display:'flex',alignItems:'center'}}>
          Saldo {Utils.formatRupiah(Gvar.saldo,'.')}
          </div>
              <Button href={'https://tiketdeposit.kulasedaya.com/index.php/topup?email=&phone='+Utils.getDataMember().no_hp+'&token='+Utils.getMyToken()} 
              color="primary" style={{float:'right'}}>Top Up</Button>
          </Paper>
          <div style={{marginTop:10}}>
              <Paper square>
                <AutoPlaySwipeableViews
                  axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                  index={activeStep}
                  onChangeIndex={this.handleStepChange}
                  enableMouseEvents
                >
                  {Gvar.tutorialSteps.map((step, index) => (
                    <div key={step.judul} onClick={()=>{
                      Gvar.homeContext.props.history.push('/bannerdetail?id='+step.id);
                    }}>
                      {Math.abs(activeStep - index) <= 2 ? (
                        <img className={classes.img} src={step.url_gambar} style={{height:'unset'}}  alt={step.judul} />
                      ) : null}
                    </div>
                  ))}
                </AutoPlaySwipeableViews>
              </Paper>
          </div>
          <Grid container style={{marginTop:8}} >
            <span style={{color:'#72747f', marginBottom:8, fontWeight:'bold'}}>Pilih Menu</span>
            <Grid item xs={12}>
              <Grid container justifyContent='space-between' alignContent='flex-start'>
                {this.state.list_kategori_utama.map((item,index) => (
                  <Grid onClick={()=>{
                    this.onClickKategoriUtama(item.jenis)
                  }} key={index} item style={{marginBottom:16, width:'calc(100% / 3 - 16px)'}}>
                    <Paper style={{padding:12, marginBottom:8}}>
                      <img src={Gvar.pathImage+item.icon} style={{width:'100%'}}></img>
                    </Paper>
                    <center>
                      <span>{item.jenis.replace(/_/g,' ')}</span>
                    </center>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </div>
        </Grow>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Dashboard);
