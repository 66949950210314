import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import Gvar from './Gvar';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ContainerLayout from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import LinearProgress from '@material-ui/core/LinearProgress';
import Utils from './Utils';
import { Box, Container, Link } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    }));

class Daftar extends Component {

    constructor(props){
        super(props);
        this.state = ({
            list_group :[],
            title : 'Daftar',
            showSnackBar : false,
            showLoading : false,
            showScreenSMSCode : false,
            no_hp : ''
        });
    }

    componentDidMount(){
        // this.props.appContext.setState({showLoading:false})
        // this.getProdukGroup();
    }

    
    onClickItem(group,jenis){
        
    }

    onCLickDaftar(){
        this.setState({showSnackBar: false});
        var nama_toko = document.getElementById("inputNamaToko");
        var no_hp = document.getElementById("inputNoHp");
        this.state.no_hp = no_hp.value;
        var password = document.getElementById("inputPassword");
        var repassword = document.getElementById("inputRePassword");
        if(nama_toko.value === ""){
            nama_toko.focus();
            this.setState({showSnackBar: true, textSnackBar :"Nama Toko/Usaha tidak boleh kosong"});
            return;
        }
        if(no_hp.value === ""){
            no_hp.focus();
            this.setState({showSnackBar: true, textSnackBar :"No hp tidak boleh kosong"});
            return;
        }
        if(password.value === ""){
            password.focus();
            this.setState({showSnackBar: true, textSnackBar :"Password tidak boleh kosong"});
            return;
        }
        if(repassword.value === ""){
            repassword.focus();
            this.setState({showSnackBar: true, textSnackBar :"Ulang Password tidak boleh kosong"});
            return;
        }

        if(repassword.value !== password.value){
            repassword.focus();
            this.setState({showSnackBar: true, textSnackBar :"Ulang password belum sama"});
            return;
        }

        this.setState({showLoading:true})
        
        var hash = Utils.getHash(nama_toko.value+no_hp.value+'123'+password.value);
        fetch(Gvar.server+'index.php/member/registrasi?nama='+nama_toko.value+'&no_hp='+no_hp.value+'&no_ktp=123&password='+password.value+'&key='+hash, {
        method: 'get',
        }).then(res=>res.json()
            ).then(res => {
                this.setState({showLoading:false});
                // console.log(res)
                if(res.status === 'ok'){
                    this.setState({ showSnackBar: true, textSnackBar :res.keterangan,showScreenSMSCode:true});
                }else{
                    this.setState({ showSnackBar: true, textSnackBar :res.keterangan});
                }
            }
            ).catch(err => {
                console.log(err.toString());
                this.setState({showLoading:false});
                this.setState({showSnackBar: true, textSnackBar :"Koneksi Gagal"});
             })
    }

    aktivasi(){
        this.setState({showSnackBar: false});
        var sms_code = document.getElementById("inputSmsCode");
        var no_hp = this.state.no_hp;
        if(sms_code.value === ""){
            sms_code.focus();
            this.setState({showSnackBar: true, textSnackBar :"Kode aktivasi tidak boleh kosong"});
            return;
        }

        this.setState({showLoading:true})
        
        var sms_code_hash = Utils.getHash(sms_code.value);
        var hash = Utils.getHash(no_hp+sms_code_hash);
        fetch(Gvar.server+'index.php/member/aktivasi?no_hp='+no_hp+'&sms_code='+sms_code_hash+'&key='+hash, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        }).then(res=>res.json()
            ).then(res => {
                this.setState({showLoading:false});
                // console.log(res)
                if(res.status === 'ok'){
                    this.setState({showScreenSMSCode:false});
                    setTimeout(() => {
                        alert(res.keterangan);
                    }, 1000);
                }else{
                    this.setState({ showSnackBar: true, textSnackBar :res.keterangan});
                }
            }
            ).catch(err => {
                console.log(err.toString());
                this.setState({showLoading:false});
                this.setState({showSnackBar: true, textSnackBar :"Koneksi Gagal"});
             })
    }
    
    handleCloseSnackbar = () => {
        this.setState({showSnackBar:false});
    }

    render() {
        return (
            <>
                <AppBar position="fixed">
                    <Toolbar variant="dense">
                        <IconButton edge="start" onClick={()=>{
                            this.props.history.goBack()
                        }} color="inherit" aria-label="menu">
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6">
                            {this.state.title}
                        </Typography>
                    </Toolbar>
                    {this.state.showLoading?<LinearProgress color="secondary"  variant="query" />:null}
                </AppBar>
                <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
                    <Container maxWidth="sm">
                        <Box my={8}>
                            <Card>
                                <CardContent>
                                    {this.state.showScreenSMSCode?(
                                        <Grid item xs={12} container direction="column">
                                            <TextField
                                                id="inputSmsCode"
                                                label="Kode Aktivasi"
                                                type="number"
                                                name="namatoko"
                                                // autoComplete="email"
                                                margin="normal"
                                                variant="outlined"
                                            />

                                            <Button disabled={this.state.showLoading} variant="contained" type="submit" onClick={()=>{
                                                this.aktivasi();
                                            }}  size="large" color="primary" style={{marginTop : 10}}>
                                                Verifikasi No HP
                                            </Button>
                                        </Grid>
                                    ):(
                                        <Grid item xs={12} container direction="column">
                                            <TextField
                                                id="inputNamaToko"
                                                label="Nama Toko/Usaha"
                                                type="text"
                                                name="namatoko"
                                                // autoComplete="email"
                                                margin="normal"
                                                variant="outlined"
                                            />

                                            <TextField
                                                id="inputNoHp"
                                                label="Nomor HP"
                                                type="number"
                                                name="nomorhp"
                                                // autoComplete="email"
                                                margin="normal"
                                                variant="outlined"
                                            />

                                            <TextField
                                                id="inputPassword"
                                                label="Password"
                                                type="password"
                                                name="password"
                                                margin="normal"
                                                variant="outlined"
                                            />

                                            <TextField
                                                id="inputRePassword"
                                                label="Ulangi Password"
                                                type="password"
                                                name="password"
                                                margin="normal"
                                                variant="outlined"
                                            />

                                            <Box>
                                                Dengan mendaftar berarti Anda menyetujui syarat dan ketentuan yang berlaku.
                                                <Link href='https://kulasedaya.com/syarat-dan-ketentuan' style={{marginLeft:8}}>Baca syarat dan ketentuan</Link>
                                            </Box>

                                            <Button disabled={this.state.showLoading} variant="contained" type="submit" onClick={()=>{
                                                this.onCLickDaftar();
                                            }}  size="large" color="primary" style={{marginTop : 10}}>
                                                Daftar
                                            </Button>
                                        </Grid>
                                    )}
                                </CardContent>
                            </Card>
                        </Box>
                    </Container>
                </Grow>
                <Snackbar
                    style={{marginTop:54}}
                    anchorOrigin={{ vertical: 'top', horizontal :'right' }}
                    key={`bottom,right`}
                    open={this.state.showSnackBar}
                    onClose={this.handleCloseSnackbar}
                    message={this.state.textSnackBar}
                />
            </>
        );
    }
}

export default (Daftar);
