import React, { Component } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import Drawer from './Drawer';
import { BrowserRouter as Router, Route, Link, Switch} from 'react-router-dom'
import Gvar from './Gvar';
import Login from './Login';
import Home from './Home';
import Daftar from './Daftar';
import Logger from './Logger';
import ForgotPassword from './ForgotPassword';
import Histori from './Histori';
import Pesan from './Pesan';
import Pengaturan from './Pengaturan';

import ProdukPrefix from './ProdukPrefix';
import ProdukPembayaran from './ProdukPembayaran';
import TokenListrik from './TokenListrik';
import TokenListrikTopup from './TokenListrikTopup';
import PLNinquiry from './PLNinquiry';
import PLNbayar from './PLNbayar';

import Pembayaran from './Pembayaran';
import PengaturanGantiPassword from './PengaturanGantiPassword';
import PengaturanGantiPIN from './PengaturanGantiPIN';
import ProdukGroup from './ProdukGroup';
import ProdukNonPrefix from './ProdukNonPrefix';

import ErrorScreen from './ErrorScreen';
import ProdukAll from './ProdukAll';
import TopUpDeposit from './TopUpDeposit';
import BannerDetail from './BannerDetail';
import BindingAccount from './BindingAccount';
class App extends Component {
  state = {
    showDrawer : false,
    menuName : 'Dashboard',
    showLoading: true,
    showSnackBar: false, 
    textSnackBar :"",
    valueTab:"",
    isLoggedIn:false
  }

  constructor(props){
    super(props);
    Gvar.appContext = this;
    // this.data_member = JSON.parse(localStorage.getItem('data_member'));
    // Gvar.data_member = this.data_member;
  }

  // handleClose = () => {
  //     this.setState({ showSnackBar: false });
  // };

  componentDidMount(){
    var isLoggedIn = localStorage.getItem('is_logged_in');
    this.setState({isLoggedIn:(isLoggedIn == 'true')}); 
    Logger.d('is logged in',isLoggedIn);

  }

  render() {
    return (
      <div>
        <Router basename={Gvar.baseName}>
            <Switch>
              {this.state.isLoggedIn?(
                <Route path="/" exact component={Home} />
              ):(
                <Route path="/" exact component={Login} />
              )}
              <Route path="/daftar" exact component={Daftar} />
              <Route path="/lupapassword" exact component={ForgotPassword} />
              <Route path="/group" component={ProdukGroup}/>
              <Route path="/allproduct" component={ProdukAll}/>
              <Route path="/pembayaran" component={Pembayaran}/>
              <Route path="/tokenlistrik" component={TokenListrik}/>
              <Route path="/tokenlistriktopup" component={TokenListrikTopup}/>
              <Route path="/histori" component={Histori}/>
              <Route path="/plninquiry" component={PLNinquiry}/>
              <Route path="/plnbayar" component={PLNbayar}/>
              <Route path="/pengaturan" component={Pengaturan}/>
              <Route path="/gantipassword" component={PengaturanGantiPassword}/>
              <Route path="/gantipin" component={PengaturanGantiPIN}/>
              <Route path="/produknonprefix" component={ProdukNonPrefix}/>
              <Route path="/pulsa" component={ProdukPrefix}/>
              <Route path="/error" component={ErrorScreen}/>
              <Route path="/paketdata" component={ProdukPrefix}/>
              <Route path="/pakettelepon" component={ProdukPrefix}/>
              <Route path="/pesan" component={Pesan}/>
              <Route path="/topupdeposit" component={TopUpDeposit}/>
              <Route path="/produkpembayaran" component={ProdukPembayaran}/>
              <Route path="/bannerdetail" component={BannerDetail}/>
              <Route path="/binding-account" component={BindingAccount}/>
            </Switch>
        </Router>
      </div>
    );
  }
}

export default (App);
